import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { RiChatNewLine, RiCalendarLine, RiCalendarEventLine } from 'react-icons/ri';
import { BsInfo } from 'react-icons/bs';
import { IoShareSocialOutline, IoLogoInstagram, IoClose } from 'react-icons/io5';
import { IoIosList } from 'react-icons/io';
import { GiAlienFire } from 'react-icons/gi';
import { Button } from '../../../ui-components';
import Icon from '../../Icon';
import { logEvent } from '../../../utils';
import logo from './android-chrome-192x192.png';
import losarcard from '../../../statics/images/Losarcard2025.jpg';
import styles from './Sidebar.module.scss';

const Sidebar = ({ toggleSidebar, ...rest }) => {
    const handleShareClick = () => {
        logEvent({
            category: 'User Event',
            action: 'User pressed <Share> button in sidebar',
        });

        if (navigator.share) {
            navigator.share({
                title: 'Tibetan Buddhist Calendar',
                text: 'Tibetan calendar app with focus on Buddhist events',
                url: 'https://tibetanbuddhistcalendar.org/',
            });
        }
    };

    const handleCardShareClick = () => {
        logEvent({
            category: 'User Event',
            action: 'User pressed <Share> button for Losar card',
        });
        fetch('/images/Losarcard2025.jpg')
            .then((response) => response.blob())
            .then((blob) => {
                const file = new File([blob], 'Losarcard2025.jpg', { type: 'image/jpeg' });
                if (navigator.canShare && navigator.canShare({ files: [file] })) {
                    navigator.share({ files: [file] });
                }
            });
    };

    const handleEventsClick = () => {
        window.localStorage.setItem('news-seen', '1');
        toggleSidebar();
    };

    return (
        <section className={styles.container} {...rest}>
            <div className={styles.header}>
                <img src={logo} alt="Logo" className={styles.logo} />
                <div className={styles.heading}>
                    <h3 className={styles.name}>Tibetan Calendar</h3>
                    <p className={styles.address}>tibetanbuddhistcalendar.org</p>
                </div>
            </div>
            {navigator.share && (
                <Button onClick={handleShareClick} context="sideNav">
                    <IoShareSocialOutline className={styles.navIcon} />
                    <span>Share</span>
                </Button>
            )}
            <Link to="/month" className={styles.link} onClick={toggleSidebar}>
                <Button context="sideNav">
                    <RiCalendarLine className={styles.navIcon} />
                    <span>Months</span>
                </Button>
            </Link>
            <Link to="/day" className={styles.link} onClick={toggleSidebar}>
                <Button context="sideNav">
                    <RiCalendarEventLine className={styles.navIcon} />
                    <span>Days</span>
                </Button>
            </Link>
            <hr />
            <Link to="/about" className={styles.link}>
                <Button context="sideNav" onClick={toggleSidebar}>
                    <BsInfo className={styles.navIcon} />
                    <span>About</span>
                </Button>
            </Link>
            <Link to="/legend" className={styles.link}>
                <Button context="sideNav" onClick={toggleSidebar}>
                    <IoIosList className={styles.navIcon} />
                    <span>Key to icons</span>
                </Button>
            </Link>
            <Link to="/yearly-events-overview" className={styles.link}>
                <Button context="sideNav" onClick={handleEventsClick}>
                    <Icon type="FLOWER" color="none" style={{ width: '20px', height: '20px', marginRight: '7px' }} />
                    <span>Events overview</span>
                </Button>
            </Link>
            <Link to="/vitality" className={styles.link}>
                <Button context="sideNav" onClick={toggleSidebar}>
                    <GiAlienFire className={styles.navIcon} />
                    <span>Vitality days</span>
                </Button>
            </Link>
            <Link to="/feedback" className={styles.link} onClick={toggleSidebar}>
                <Button context="sideNav">
                    <RiChatNewLine className={styles.navIcon} />
                    <span>Feedback</span>
                </Button>
            </Link>
            <a
                href="https://www.instagram.com/tibetan_buddhist_calendar/"
                target="_blank"
                className={styles.link}
                rel="noreferrer"
            >
                <Button context="sideNav" onClick={toggleSidebar}>
                    <IoLogoInstagram className={styles.navIcon} />
                    <span>Instagram</span>
                </Button>
            </a>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
            <img src={losarcard} alt="Losar Tashi Delek" className={styles.card} onClick={handleCardShareClick} />
            {navigator.share && (
                <Button onClick={handleCardShareClick} context="sideNav" style={{ marginTop: '-8px' }}>
                    <IoShareSocialOutline className={styles.navIcon} />
                    <span style={{ fontWeight: 'normal' }}>Share this card with your friends</span>
                </Button>
            )}
            <Button context="sideNav" onClick={toggleSidebar} className={styles.collapse}>
                <IoClose className={styles.navIcon} />
            </Button>
        </section>
    );
};

Sidebar.propTypes = {
    /** callback to close the sidebar */
    toggleSidebar: PropTypes.func,
    /** additional props will be applied to <section> */
};

Sidebar.defaultProps = {
    toggleSidebar: undefined,
};

export default Sidebar;
