import React from 'react';
import { TibetanDate } from 'tibetan-date-calculator';
import { Button, Modal } from '../../ui-components';
import styles from './NewsModal.module.scss';

const NewsModal = ({ setDate }) => {
    const yesterdayTimestamp = new Date().getTime() - 1 * 24 * 60 * 60 * 1000;
    const storeValue = +window.localStorage.getItem('news-seen');
    const initState = !(storeValue === 2 || storeValue > yesterdayTimestamp);

    const [isOpen, setIsOpen] = React.useState(initState);

    const handleSeenClick = () => {
        window.localStorage.setItem('news-seen', '2');
        setIsOpen(false);
    };

    const handleShowMeLink = () => {
        handleSeenClick();
        setDate(new TibetanDate(`2025-02-28`));
    };

    const handleLaterClick = () => {
        window.localStorage.setItem('news-seen', new Date().getTime());
        setIsOpen(false);
    };

    return (
        <Modal isOpen={isOpen}>
            <h1>Latest changes</h1>
            <p>
                In this current update we added a new Losar Card for the upcoming Wooden Snake year (artwork:
                @alex.makes.drawings).
            </p>
            <p>You are welcome to share it with your loved onces. Losar Tashi Delek!</p>
            <div>
                <Button onClick={handleShowMeLink} context="large">
                    Show me
                </Button>
            </div>
            <div className={styles.buttons}>
                <Button onClick={handleLaterClick}>Remind me later</Button>
                <Button onClick={handleSeenClick} context="outline">
                    Thanks, got it
                </Button>
            </div>
            <p> </p>
        </Modal>
    );
};

export default NewsModal;
