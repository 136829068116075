import * as React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { TibetanDate } from 'tibetan-date-calculator';
import { RiCalendarLine, RiCalendarCheckLine, RiCalendarEventLine, RiMoreFill } from 'react-icons/ri';
import { IoShareSocialOutline } from 'react-icons/io5';
import { AiOutlineDownload } from 'react-icons/ai';
import { Button } from '../../../ui-components';
import { logEvent } from '../../../utils';
import styles from './FooterNav.module.scss';

const FooterNav = ({ showInstallButton = false, onInstallClick, onMoreClick, setDate, currentDate, ...rest }) => {
    const location = useLocation();
    const [disableTodayButton, setDisableTodayButton] = React.useState(true);

    // navigation button
    React.useEffect(() => {
        const today = new TibetanDate();
        const isTodayCurrentDay = currentDate.westernDateStr === today.westernDateStr;
        const isTodayInCurrentMonth =
            currentDate.monthObj.startDateStr <= today.westernDateStr &&
            currentDate.monthObj.endDateStr >= today.westernDateStr;

        if (location.pathname === '/day' && !isTodayCurrentDay) {
            setDisableTodayButton(false);
        } else if (location.pathname === '/month' && !isTodayInCurrentMonth) {
            setDisableTodayButton(false);
        } else {
            setDisableTodayButton(true);
        }
    }, [currentDate, location]);

    const handleTodayClick = () => {
        logEvent({
            category: 'User Event',
            action: 'User pressed <Today> button',
        });

        setDate(new TibetanDate());
    };

    const handleShareClick = () => {
        logEvent({
            category: 'User Event',
            action: 'User pressed <Share> button',
        });

        if (navigator.share) {
            navigator.share({
                title: 'Tibetan Buddhist Calendar',
                text: 'Tibetan calendar app with focus on Buddhist events',
                url: 'https://tibetanbuddhistcalendar.org/',
            });
        }
    };

    return (
        <footer {...rest}>
            <Button context="nav" onClick={onMoreClick}>
                <RiMoreFill className={styles.navIcon} />
                <span>More</span>
            </Button>
            {navigator.share && (
                <Button onClick={handleShareClick} context="nav">
                    <IoShareSocialOutline className={styles.navIcon} />
                    <span>Share</span>
                </Button>
            )}
            {showInstallButton && (
                <Button onClick={onInstallClick} context="nav">
                    <AiOutlineDownload className={styles.navIcon} />
                    <span>Install</span>
                </Button>
            )}
            <Link to="/month" className={styles.link}>
                <Button context="nav" disabled={location.pathname === '/month'}>
                    <RiCalendarLine className={styles.navIcon} />
                    <span>Months</span>
                </Button>
            </Link>
            <Link to="/day" className={styles.link}>
                <Button context="nav" disabled={location.pathname === '/day'}>
                    <RiCalendarEventLine className={styles.navIcon} />
                    <span>Days</span>
                </Button>
            </Link>
            {(location.pathname === '/month' || location.pathname === '/day') && (
                <Button onClick={handleTodayClick} context="nav" disabled={disableTodayButton}>
                    <RiCalendarCheckLine className={styles.navIcon} />
                    <span>Today</span>
                </Button>
            )}
        </footer>
    );
};

FooterNav.propTypes = {
    /** should installation button be shown */
    showInstallButton: PropTypes.bool,
    /** callback function to run when install button was clicked */
    onInstallClick: PropTypes.func,
    /** callback function to run when more button was clicked - open sidebar */
    onMoreClick: PropTypes.func,
    /** callback function to set currently shown date */
    setDate: PropTypes.func.isRequired,
    /** the current date */
    currentDate: PropTypes.object.isRequired,
    /** additional props will be applied to <footer> */
};

FooterNav.defaultProps = {
    showInstallButton: false,
    onInstallClick: undefined,
    onMoreClick: undefined,
};

export default FooterNav;
